import { createMuiTheme } from '@material-ui/core/styles';

const Pink = createMuiTheme({
  palette: {
    primary: {
      main: '#f8bbd0',
    },
    secondary: {
      main: '#ba68c8',
    },
  },
});

export default Pink;
