import React from 'react';
import Oopseis from './Oopseis.js';

function NmParallax(props) {
  return(
    <div>
      Nick's Shop
      <Oopseis/>
    </div>
  )
}
export default NmParallax;
